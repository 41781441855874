<template>
  <div
    class="items-center gap-2 rounded-lg bg-gray-150 px-4 py-3 text-sm leading-4"
    :style="getPromotionStyle(customData)"
  >
    <Headline v-if="content?.title" tag="div">
      <span v-dompurify-html="content.title" />
    </Headline>
    <p v-if="content?.text" v-dompurify-html="content.text" />
  </div>
</template>

<script setup lang="ts">
type Props = {
  customData?: Promotion['customData']
  view: 'category' | 'product' | 'basket'
}

const props = withDefaults(defineProps<Props>(), {
  customData: () => ({}),
})

const content = computed(() =>
  getPromotionContent(props.customData, props.view),
)
</script>
