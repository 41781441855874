<template>
  <div v-if="productPromotionId" class="flex items-start gap-1">
    <ProductPromotionBadge
      v-for="{ id, customData } in orderedPromotions"
      :key="id"
      :custom-data="customData"
      :class="itemClass"
      :view="view"
    />
  </div>
</template>

<script setup lang="ts">
import type { Product } from '@scayle/storefront-nuxt'
import { sort } from 'radash'

type Props = {
  product: Product
  view?: 'category' | 'product' | 'basket'
  itemClass?: string
}

const props = withDefaults(defineProps<Props>(), {
  view: 'product',
  itemClass: '',
})

const { productPromotionId, applicablePromotions } = await useProductPromotions(
  props.product,
)

const orderedPromotions = computed(() => {
  return sort(applicablePromotions.value, (item) => item.priority)
})
</script>
