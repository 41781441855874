import {
  getFirstAttributeValue,
  type Product,
  type Variant,
  type WishlistItem,
  type Price as BapiPrice,
} from '@scayle/storefront-nuxt'

import { Action } from '~/plugins/toast'

/**
 * wraps useWishlist and adds additional functionality attatched to one specific product
 * variantIds are is only for tracking
 */

export interface FimWishlistItem extends WishlistItem {
  variantId: number | null
  product: Product & { price: BapiPrice }
}

interface UseWishlistParams {
  product: Product
  variant?: Variant
}

export const useFimWishlist = (options: UseWishlistParams) => {
  const product = options.product
  const variant = options?.variant || undefined

  const instance = useNuxtApp()

  const { $i18n, $fimLocalePath } = instance

  const { alert } = useToast()

  const tracking = useTrackingEvents()
  const wishlist = useWishlist({
    params: WISHLIST_WITH,
  })

  const { trackAddToWishlist, trackRemoveFromWishlist } = tracking

  const isUpdating = ref<boolean>(false)

  const isInWishlist = computed(() => {
    isUpdating.value = false
    return wishlist.contains({ productId: product.id })
  })

  const toggleWishlistItem = () => {
    isUpdating.value = true
    const wasInWishlist = isInWishlist.value

    wishlist.toggleItem({ productId: product.id })
    if (wasInWishlist) {
      trackRemoveFromWishlist(product, variant)
    } else {
      trackAddToWishlist(product, variant)
    }

    const name =
      getFirstAttributeValue(product.attributes, 'name')?.label ||
      $i18n.t('wishlist.product')

    const message = !wasInWishlist
      ? $i18n.t('wishlist.notification.add_to_wishlist', {
          productName: name,
        })
      : $i18n.t('wishlist.notification.remove_from_wishlist', {
          productName: name,
        })

    alert(
      message,
      !wasInWishlist ? Action.route : Action.confirm,
      !wasInWishlist ? $fimLocalePath(routeList.wishlist) : undefined,
    )
  }

  return extendPromise(
    Promise.all([wishlist, tracking]).then(() => ({})),
    {
      ...wishlist,
      isUpdating,
      isInWishlist,
      toggleWishlistItem,
    },
  )
}

export default useFimWishlist
