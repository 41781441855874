<template>
  <FimButton
    v-if="isSyteEnabled && variantImageUrl"
    class="syte-discovery group flex items-center justify-center"
    :class="{ 'mr-1 !gap-0': isIcon }"
    :type="isIcon ? 'raw' : 'secondary'"
    :no-padding="isIcon"
    :data-image-src="variantImageUrl"
    :data-sku="variantOrProductId"
    :data-placement="placement"
    :data-tracking-label="`Similar Items | ${placement}`"
    :aria-label="$t('pdp.shop_similars')"
    @click="trackClick"
  >
    <template #icon>
      <LazyIconFielmannGlassesSimilar class="pointer-events-none size-6" />
    </template>
    <template v-if="!isIcon">
      {{ $t('pdp.shop_similars') }}
    </template>
  </FimButton>
</template>

<script setup lang="ts">
import type { ProductImage } from '@scayle/storefront-core'

// images/46dc81e1d27819d030f8b2ebc519609c.png
// https://fim-prev.cdn.aboutyou.cloud/images/46dc81e1d27819d030f8b2ebc519609c.png?trim=1&brightness=1&width=384&height=512&quality=85&bg=FFFFFF

const props = defineProps({
  variantOrProductId: {
    type: Number as PropType<number>,
    required: true,
  },
  productImage: {
    type: Object as PropType<ProductImage>,
    required: true,
  },
  placement: {
    type: String as PropType<SyteValidPlacement>,
    required: true,
  },
  type: {
    type: String as PropType<'icon' | 'button'>,
    default: 'icon',
  },
})

const { isSyteEnabled } = useFeatureToggles()
const { trackButtonClick } = await useTrackingEvents()
const image = useImage()

const trackClick = (event: Event) => {
  const element = event?.target as HTMLButtonElement
  trackButtonClick(element)
}

const variantImageUrl = computed(() =>
  image(props.productImage.hash, { trim: 1 }, { provider: 'fim' }),
)
const isIcon = computed(() => props.type === 'icon')
</script>
