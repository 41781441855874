<template>
  <div
    v-if="enabled"
    ref="root"
    class="bazaar-voice"
    :class="visible ? '' : 'invisible pointer-events-none'"
    :data-bv-show="show"
    :data-bv-product-id="id"
  ></div>
</template>
<script setup lang="ts">
const AUTO_HIDE: string[] = ['inline_rating', 'rating_summary']
const DISABLED_ROUTES = ['/']

defineOptions({ name: 'BazaarVoice' })

type Props = {
  id: string | number
  show: 'reviews' | 'inline_rating' | 'rating_summary'
}

const props = defineProps<Props>()

const $currentShop = useCurrentShop()
const route = useRoute()
const root = templateRef<Element>('root')
const visible = ref<boolean>(!AUTO_HIDE.includes(props.show))
const observer = ref<MutationObserver>()

const enabled = computed(
  () =>
    !DISABLED_ROUTES.includes(route.path) &&
    $currentShop.value.bazaarVoiceScript,
)

const script = enabled.value
  ? [
      {
        src: $currentShop.value.bazaarVoiceScript,
        async: true,
        defer: true,
        hid: 'bazaarvoice-head-script',
        once: true,
      },
    ]
  : []

useHead(() => ({
  script,
}))

onMounted(() => {
  if (!enabled.value) {
    return
  }

  const checkRatings = () => {
    if (root.value?.textContent) {
      // try to find the reviewCount itemprop
      const reviewCount: HTMLMetaElement | null = root.value?.querySelector(
        '[itemprop="reviewCount"]',
      )
      // reviews don't contain the itemprop reviewCount
      // but we can search for the .bv-rating-none element instead
      const fallback =
        props.show !== 'reviews' || root.value?.querySelector('.bv-rating-none')
          ? '0'
          : '1'

      // Hide any ratings without reviews
      visible.value = parseFloat(reviewCount?.content ?? fallback) !== 0
    }
  }

   // Only hide hide empty elements which are not always visible
  if (
    root.value &&
    AUTO_HIDE.includes(props.show) &&
    !root.value?.textContent
  ) {
    // Create an observer instance linked to the callback function
    observer.value = new MutationObserver(() => checkRatings())
    // Start observing the target node for configured mutations
    observer.value.observe(root.value, {
      attributes: false,
      childList: true,
      subtree: false,
    })
  } else if (root.value && root.value?.textContent) {
    checkRatings()
  }
})

onUnmounted(() => {
  if (observer.value) {
    observer.value.disconnect()
  }
})
</script>
