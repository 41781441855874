<template>
  <div v-if="productPromotionId" class="flex flex-col flex-wrap gap-1">
    <template v-for="{ id, customData } in orderedPromotions">
      <ProductPromotionBanner
        v-if="isPromotionVisible(customData, view)"
        :key="id"
        :custom-data="customData"
        :view="view"
        :class="itemClass"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { Product } from '@scayle/storefront-nuxt'
import { sort } from 'radash'

type Props = {
  product: Product
  view?: 'category' | 'product' | 'basket'
  itemClass?: string
}

const props = withDefaults(defineProps<Props>(), {
  view: 'product',
  itemClass: '',
})

const { productPromotionId, applicablePromotions } = await useProductPromotions(
  props.product,
)

const orderedPromotions = computed(() => {
  return sort(applicablePromotions.value, (item) => item.priority)
})
</script>
