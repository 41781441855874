<template>
  <Badge
    v-if="content?.title"
    size="small"
    type="info"
    class="px-2 py-1"
    :label="content.title"
    data-testid="promotion-badge"
    :style="getPromotionStyle(customData)"
  >
    <Headline v-if="content?.title" tag="span" class="text-xs">
      <span v-dompurify-html="content.title" />
    </Headline>
  </Badge>
</template>

<script setup lang="ts">
type Props = {
  customData?: Promotion['customData']
  view: 'category' | 'product' | 'basket'
}

const props = withDefaults(defineProps<Props>(), {
  customData: () => ({}),
})

const content = computed(() =>
  getPromotionContent(props.customData, props.view),
)
</script>
